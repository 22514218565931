@import url('https://fonts.googleapis.com/css2?family=Comforter&display=swap');

body {
  margin: 0;
}

.logo {
  font-family: 'Comforter', cursive;
  font-size: 64px;
  text-decoration: none!important;
  outline: none!important;
  border: none!important;
  outline-offset: 0!important;
  margin-bottom: 0!important;
  padding-bottom: 0!important;
}

.logo.small {
  font-size: 36px;
}

.brand {
  font-family: 'Comforter', cursive;
  font-size: 48px;
  text-decoration: none!important;
  outline: none!important;
  border: none!important;
  outline-offset: 0!important;
  margin-bottom: 0!important;
  padding-bottom: 0!important;
}
